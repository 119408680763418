<template>
  <div id="ejcon" class="ejcon"  ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
<!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration" dur="3" />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">年级运动数据动态</div>
          </div>
          <div class="return" @click="returnBack">
            <img src="@/assets/return.png" alt="">
          </div>
<!-- 数据 -->
<div class="digital-flop">
    <div
      class="digital-flop-item"
      v-for="item in digitalFlopData"
      :key="item.title"
    >
      <div class="digital-flop-title">{{ item.title }}</div>
      <div class="digital-flop">
        <dv-digital-flop
          :config="item.number"
          style="width:100px;height:50px;"
        />
          <div class="unit">{{ item.unit }}</div>
      </div>
    </div>

    <dv-decoration-10  />
  </div>

  <!-- 年级 -->
  <div class="classInfo">
    <div class="classInfo_Item" v-for="(item,index) in array" :key="index">
      <dv-border-box-13>
        <h3 class="title fixed1">{{item.title}}</h3>
        <div class="classInfo_Item_con">
          <div class="left">
            <div class="item">
              <h5>平均时长</h5>
              <em>164 min</em>
            </div>
            <div class="item end">
              <h5>平均身高体重</h5>
              <em>100 cm / 26kg</em>
            </div>
          </div>

          <div class="right">
            <dv-scroll-board class="dv-scr-board" :config="item.config" />
          </div>
          
        </div>
      </dv-border-box-13>
      
    </div>
  </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      digitalFlopData: [],
      array: [{title: '一年级运动数据',avgtime:"120",agx:"124",config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生</span>', 
        '<span style="color:#9abeff;font-weight:bold">女生</span>',
        '<span style="color:#9abeff;font-weight:bold">平均时长(min)</span>'],
        data: [
          ['跳绳', '96', "112", "104"],
          ['坐位体前屈',  '96', "112", "104"],
          ['立定跳远', '96', "112", "104"],
          ['跑步',  '96', "112", "104"],
          ['800米跑', '96', "112", "104"],
          ['仰卧起坐',  '96', "112", "104"],
          ['踢足球',  '96', "112", "104"],
          ['踢毽子', '96', "112", "104"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','center'],
        columnWidth: [100],
      }},{title: '二年级运动数据',avgtime:"120",agx:"124",config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生</span>', 
        '<span style="color:#9abeff;font-weight:bold">女生</span>',
        '<span style="color:#9abeff;font-weight:bold">平均时长(min)</span>'],
        data: [
          ['跳绳', '96', "112", "104"],
          ['坐位体前屈',  '96', "112", "104"],
          ['立定跳远', '96', "112", "104"],
          ['跑步',  '96', "112", "104"],
          ['800米跑', '96', "112", "104"],
          ['仰卧起坐',  '96', "112", "104"],
          ['踢足球',  '96', "112", "104"],
          ['踢毽子', '96', "112", "104"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','center'],
        columnWidth: [100],
      }},{title: '三年级运动数据',avgtime:"120",agx:"124",config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生</span>', 
        '<span style="color:#9abeff;font-weight:bold">女生</span>',
        '<span style="color:#9abeff;font-weight:bold">平均时长(min)</span>'],
        data: [
          ['跳绳', '96', "112", "104"],
          ['坐位体前屈',  '96', "112", "104"],
          ['立定跳远', '96', "112", "104"],
          ['跑步',  '96', "112", "104"],
          ['800米跑', '96', "112", "104"],
          ['仰卧起坐',  '96', "112", "104"],
          ['踢足球',  '96', "112", "104"],
          ['踢毽子', '96', "112", "104"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','center'],
        columnWidth: [100],
      }},{title: '四年级运动数据',avgtime:"120",agx:"124",config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生</span>', 
        '<span style="color:#9abeff;font-weight:bold">女生</span>',
        '<span style="color:#9abeff;font-weight:bold">平均时长(min)</span>'],
        data: [
          ['跳绳', '96', "112", "104"],
          ['坐位体前屈',  '96', "112", "104"],
          ['立定跳远', '96', "112", "104"],
          ['跑步',  '96', "112", "104"],
          ['800米跑', '96', "112", "104"],
          ['仰卧起坐',  '96', "112", "104"],
          ['踢足球',  '96', "112", "104"],
          ['踢毽子', '96', "112", "104"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','center'],
        columnWidth: [100],
      }},{title: '五年级运动数据',avgtime:"120",agx:"124",config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生</span>', 
        '<span style="color:#9abeff;font-weight:bold">女生</span>',
        '<span style="color:#9abeff;font-weight:bold">平均时长(min)</span>'],
        data: [
          ['跳绳', '96', "112", "104"],
          ['坐位体前屈',  '96', "112", "104"],
          ['立定跳远', '96', "112", "104"],
          ['跑步',  '96', "112", "104"],
          ['800米跑', '96', "112", "104"],
          ['仰卧起坐',  '96', "112", "104"],
          ['踢足球',  '96', "112", "104"],
          ['踢毽子', '96', "112", "104"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','center'],
        columnWidth: [100],
      }},{title: '六年级运动数据',avgtime:"120",agx:"124",config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生</span>', 
        '<span style="color:#9abeff;font-weight:bold">女生</span>',
        '<span style="color:#9abeff;font-weight:bold">平均时长(min)</span>'],
        data: [
          ['跳绳', '96', "112", "104"],
          ['坐位体前屈',  '96', "112", "104"],
          ['立定跳远', '96', "112", "104"],
          ['跑步',  '96', "112", "104"],
          ['800米跑', '96', "112", "104"],
          ['仰卧起坐',  '96', "112", "104"],
          ['踢足球',  '96', "112", "104"],
          ['踢毽子', '96', "112", "104"],
        ],
        rowNum: 6, //表格行数
        headerHeight: 42,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','center'],
        columnWidth: [100],
      }}]
      
    }
  },
  components: {
   
  },
  mounted() {
    this.cancelLoading()
    const { createData } = this

    createData()

    setInterval(createData, 30000)
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
  //  加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    createData () {
      //const { randomExtend } = this

      this.digitalFlopData = [
        {
          title: '班级数量',
          number: {
            number: [36],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#4d99fc',
            }
          },
          unit: '个'
        },
        {
          title: '平均运动时长',
          number: {
            number: [5],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#f46827',
            }
          },
          unit: 'MIN'
        },
        {
          title: '平均身高',
          number: {
            number: [110],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#40faee',
            }
          },
          unit: 'CM'
        },
        {
          title: '平均体重',
          number: {
            number: [42],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#4d99fc',
            }
          },
          unit: 'KG'
        },
        {
          title: '男生平均运动时长',
          number: {
            number: [25],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#f46827',
            }
          },
          unit: 'MIN'
        },
        {
          title: '女生平均运动时长',
          number: {
            number: [5],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#40faee',
            }
          },
          unit: 'MIN'
        },
        {
          title: '男生平均身高',
          number: {
            number: [120],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#4d99fc',
            }
          },
          unit: 'CM'
        },
        {
          title: '女生平均身高',
          number: {
            number: [126],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#f46827',
            }
          },
          unit: 'CM'
        },
        {
          title: '男生平均体重',
          number: {
            number: [30],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#40faee',
            }
          },
          unit: 'KG'
        },
        {
          title: '女生平均体重',
          number: {
            number: [35],
            content: '{nt}',
            textAlign: 'right',
            style: {
              fill: '#40faee',
            }
          },
          unit: 'KG'
        }
      ]
    },
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    },
    returnBack(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="scss" scoped>
.bg{
  padding: 0;
}
.ejcon {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
.top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
  margin-bottom: 16px;

  .header-center-decoration {
    width: 46%;
    height: 60px;
    margin-top: 20px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
    letter-spacing:8px;
    color: #0ebe9b;
  }
}
  }
.return{
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  left: 20px;
  top: 52px;
  width: 80px;
  height: 80px;
  background: rgba(0, 250, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #0f319b;
  img{
    display: block;
    text-align: center;
    width: 36px;
    margin: 24px auto;
  }
}

  .digital-flop {
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(8, 23, 62, 0.5);
  margin-bottom: 30px;

  .dv-decoration-10 {
    position: absolute;
    width: 98%;
    left: 1%;
    height: 5px;
    bottom: 0px;
    
  }

  .digital-flop-item {
    padding-top: 26px;
    width: 11%;
    height: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 2px solid #12327d;
    border-right: 2px solid #12327d;
  }

  .digital-flop-title {
    font-size: 22px;
    margin-bottom: 40px;
    color: #fff;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}
.classInfo{
  margin: 0 20px;
  >div:nth-child(3n){
    margin-right: 0;
  }
  >div:nth-child(-n+3){
    margin-bottom: 36px;
  }
  .classInfo_Item{
    width: 610px;
    height: 356px;
    float: left;
    margin-right: 20px;
    
    .left{
      padding-left: 20px;
      float: left;
      .item{
        width: 220px;
        height: 110px;
        background: #072652;
        border-radius: 10px;
        text-align: center;
        h5{
          
          font-size: 20px;
          color: #fff;
          padding-top: 20px;
          font-weight: 500;
        }
        em{
            display: block;
            font-size: 24px;
            color: #fbc034;
            font-style: normal;
            margin-top: 30px;
        }
      }
      .end{
          margin-top: 30px;
        }
    }
    .right{
      float: right;
      padding-right: 20px;
      .dv-scr-board {
      width: 330px;
      height: 276px;
      color: #d4eaf6;
      ::v-deep .row-item{
        height: 44px !important;
        line-height:44px !important;
        font-size: 14px !important;
        color: #9abeff !important;
      }
      
    }
    }
  }
}

}
</style>
